<template>
    <div>
        <!-- TAB INPUTS -->
        <input id="page-tab-payment" class="page-tab-input" name="page-tab-input" type="radio" checked />
        <input id="page-tab-stripe" class="page-tab-input" name="page-tab-input" type="radio" />
        <input id="page-tab-billing" class="page-tab-input" name="page-tab-input" type="radio" />

        <!-- TABS CONTAINER -->
        <div class="company-settings-header page-tabs-container">
            <h1>{{ header }}</h1>
            <label class="page-tab page-tab-payment" for="page-tab-payment">Payment</label>
            <label class="page-tab page-tab-stripe" for="page-tab-stripe">Stripe</label>
            <label class="page-tab page-tab-billing" for="page-tab-billing">Billing</label>
        </div>
        <!-- TABBED PAGE SECTIONS -->
        <section class="tabbed-page payment">
            <ChirpSettings v-if="!loading" :data_source="rows['payment']" :level="'company'" :level_id="level_id" />
        </section>

        <section class="tabbed-page stripe">
            <div v-if="!has_stripe">
                <p>You currently do not have Stripe enabled. Click below to enable it.</p>
                <button class="primary" @click="enableStripe" :disabled="enabling">
                    <span v-if="!enabling">Connect with Stripe</span
                    ><Loading
                        outerHeight="16px"
                        outerWidth="16px"
                        height="16px"
                        width="16px"
                        :color="'white transparent transparent transparent'"
                        :borderWidth="'3px'"
                        v-else
                    />
                </button>
            </div>
            <div v-else>
                <p>You currently have Stripe enabled.</p>
            </div>
        </section>

        <section class="tabbed-page billing">
            <ChirpSettings v-if="!loading" :data_source="rows['billing']" :level="'company'" :level_id="level_id" />
        </section>
    </div>
</template>

<script>
    const api_root = 'settings/list/company';

    import { settings } from '@/util/apiRequests';
    import Loading from '@/components/general/loading/loading';
    import ChirpSettings from '@/components/general/list/ChirpSettings';

    export default {
        name: 'CompanyPayment',
        components: { ChirpSettings, Loading },
        data() {
            return {
                loading: 1,
                rows: {
                    payment: [],
                    billing: []
                },
                level_id: 0,
                has_stripe: false,
                enabling: true
            };
        },
        computed: {
            header() {
                return this.headerText || this.pageMeta.title;
            }
        },
        methods: {
            async init() {
                this.level_id = this.$store.state.user.company_id;
                let res = await this.$api.get(`/${api_root}/${this.level_id}/payment`);
                this.rows['payment'] = res.data;
                res = await this.$api.get(`/${api_root}/${this.level_id}/billing`);
                this.rows['billing'] = res.data;
                this.loading = 0;
                let temp_id = sessionStorage.getItem('acct_id');
                if (temp_id === 'undefined') {
                    temp_id = null;
                }
                if (temp_id) {
                    await this.verifyId(temp_id);
                } else {
                    await this.checkStripe();
                }
                this.enabling = false;
            },
            async enableStripe() {
                this.enabling = true;
                // store the temporary account id in the local storage
                const { data } = await this.$api.get(settings.connectStripe());

                sessionStorage.setItem('acct_id', data.acct_id);

                window.location.href = data.url;

                this.enabling = false;
            },
            async checkStripe() {
                const { data } = await this.$api.get(settings.checkStripe());

                this.enabling = false;

                this.has_stripe = data;
            },
            async verifyId(id) {
                const { data } = await this.$api.get(settings.verifyStripe(id));

                if (data.success) {
                    this.$toasted.success(data.message);
                    this.has_stripe = true;
                } else {
                    this.$toasted.error(data.message);
                    data.has_stripe = false;
                }

                sessionStorage.removeItem('acct_id');
                this.enabling = false;
            }
        },
        async created() {
            await this.init();
        }
    };
</script>